.app {
  width: 100vw;
}

.body {
  margin: 0 auto;
  width: 100%;
  padding: 32px;
  max-width: 1440px;
  @media (min-width: 760px) {
    padding: 64px;
  }
}
