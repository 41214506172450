@import url("https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@300&display=swap");

* {
  box-sizing: border-box;
  font-family: Arial, Helvetica, sans-serif;
}

body {
  border: none !important;
  margin: 0px !important;
  padding: 0 !important;
  background: #2c2d2e;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}
